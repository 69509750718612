<template>
  <div id="control">
    <div class="control-info">
      <span>
        <label>会员等级：</label>
        <i class="el-icon-star-on" v-for="(item, i) in starCount" :key="i"></i>
      </span>
      <span>会员到期日：2021-12-31</span>
      <span>
        <a href="###">立即续费</a>
      </span>
      <span v-if="userInfo.loginTime"
        >上次登录时间：{{ userInfo.loginTime }}</span
      >
      <span v-else>首次登录</span>
    </div>
    <div class="control-cont">
      <div class="control-cont__menu">
        <dl v-for="(item, i) in menu" :key="i">
          <dt @click="handle(item)">
            <img src="@/assets/image/home.png" v-show="item.name == '首页'" />
            <!-- <img
              src="@/assets/image/product.png"
              v-show="item.name == '集成公司搜索'"
            /> -->
            <img
              src="@/assets/image/company.png"
              v-show="item.name == '会员续费'"
            />
            <img
              src="@/assets/image/market.png"
              v-show="item.name == '推广宣传'"
            />
            <img
              src="@/assets/image/bid.png"
              v-show="item.name == '标地管理'"
            />
            <img
              src="@/assets/image/bid.png"
              v-show="item.name == '产品管理'"
            />
            <router-link v-if="item.path" :to="item.path">{{
              item.name
            }}</router-link>
          </dt>
          <template v-if="item.flag">
            <dd v-for="(children, i) in item.children" :key="i">
              <router-link :to="children.path">{{ children.name }}</router-link>
            </dd>
          </template>
        </dl>
      </div>
      <router-view class="control-cont__view"></router-view>
    </div>
  </div>
</template>

<script>
export default {
  name: "control",
  data() {
    return {
      menu1: [
        {
          name: "首页",
          path: "/control/companyb",
          flag: false,
        },
        // {
        //   name: "集成公司搜索",
        //   flag: false,
        //   path: "/control/search",
        // },
        {
          name: "会员续费",
          flag: false,
          path: "/control/member",
        },
      ],
      menu2: [
        {
          name: "首页",
          flag: false,
          path: "/control/companyc",
        },
        // {
        //   name: "集成公司搜索",
        //   flag: false,
        //   path: "/control/search",
        // },
        {
          name: "会员续费",
          flag: false,
          path: "/control/member",
        },
      ],
    };
  },
  computed: {
    menu() {
      const type = JSON.parse(localStorage.user)?.type;
      return this[`menu${type}`];
    },
    userInfo() {
      return this.$store.state.userInfo;
    },
    starCount() {
      return this.userInfo?.vip > 0 ? 5 : 3;
    },
  },
  mounted() {
    if (!this.userInfo) {
      this.$router.push({
        path: "/login",
        query: {
          redirect: this.$router.currentRoute.path,
        },
      });
    }
  },
  beforeMount() {
    this.getMenu1();
    this.getMenu2();
  },
  methods: {
    handle(item) {
      item.flag = !item.flag;
    },
    getMenu1() {
      this.$axios.get("/member/benterprisePage").then((res) => {
        if (res.state === 200) {
          let code = res.data.code;
          this.$axios.get(`/member/selectMemberDetail/${code}`).then((res) => {
            if (res.state === 200) {
              sessionStorage.setItem("status", res.data.status);
              this.status = sessionStorage.getItem("status");
              if (this.status == 2) {
                this.menu1.splice(
                  1,
                  0,
                  {
                    name: "产品管理",
                    flag: false,
                    path: "/control/product",
                    children: [
                      {
                        name: "产品管理",
                        path: "/control/product/index",
                      },
                      // {
                      //   name: "新增产品",
                      //   path: "/control/product/add",
                      // },
                    ],
                  },
                  {
                    flag: false,
                    name: "推广宣传",
                    path: "/control/bmarketing",
                  }
                );
              }
            }
          });
        } else {
          this.$message.error(res.message);
        }
      });
    },
    getMenu2() {
      this.$axios.get("/member/centerprisePage").then((res) => {
        if (res.state === 200) {
          let code = res.data.code;
          this.$axios.get(`/member/selectMemberDetail/${code}`).then((res) => {
            if (res.state === 200) {
              sessionStorage.setItem("status", res.data.status);
              this.status = sessionStorage.getItem("status");
              if (this.status == 2) {
                this.menu2.splice(
                  1,
                  0,
                  {
                    name: "标地管理",
                    flag: false,
                    path: "/control/bid",
                    children: [
                      {
                        name: "标地管理",
                        path: "/control/bid/index",
                      },
                      // {
                      //   name: "新增标地",
                      //   path: "/control/bid/add",
                      // },
                    ],
                  },
                  {
                    flag: false,
                    name: "推广宣传",
                    path: "/control/cmarketing",
                  }
                );
              }
            }
          });
        } else {
          this.$message.error(res.message);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
#control {
  width: 1200px;
  height: auto;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  margin: 68px auto 0;
  .control-info {
    height: 120px;
    font-size: 18px;
    color: #333;
    line-height: 24px;
    padding: 30px 0 0;
    display: flex;
    span {
      &:first-child {
        display: flex;
        i {
          color: #fa6400;
          font-size: 24px;
        }
      }
      &:nth-child(2) {
        margin: 0 25px;
      }
      &:nth-child(3) {
        a {
          color: #3883ff;
          text-decoration: none;
        }
      }
      &:last-child {
        margin-left: auto;
      }
    }
  }
  .control-cont {
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    &__menu {
      width: 230px;
      height: auto;
      display: flex;
      flex-direction: column;
      margin: 0 0 114px;
      border: 1px solid #ededed;
      padding: 10px 0 20px 30px;
      dl {
        color: #333;
        dt {
          line-height: 50px;
          font-size: 20px;
          display: flex;
          align-items: center;
          margin: 10px 0 0;
          img {
            width: 24px;
            height: 24px;
            // background: #ddd;
            margin: 0 20px 0 0;
          }
          a {
            color: #333;
            text-decoration: none;
            &.active {
              color: #000;
            }
          }
        }
        dd {
          line-height: 40px;
          font-size: 16px;
          padding: 0 0 0 44px;
          a {
            color: #333;
            text-decoration: none;
            &.active {
              color: #3883ff;
            }
          }
        }
      }
    }
    &__view {
      width: 940px;
      ::v-deep.crumbs {
        height: 50px;
        font-size: 16px;
        color: #333;
        line-height: 1;
        display: flex;
        a {
          text-decoration: none;
          color: #333;
        }
        label {
          margin: 0 10px;
        }
      }
      ::v-deep.search {
        height: 80px;
        border: 1px solid #ededed;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 32px 0 0;
        & > div {
          &:first-child {
            display: flex;
            align-items: center;
            label {
              flex-shrink: 0;
              font-size: 20px;
              color: #333;
              line-height: 1;
              padding: 0 16px 0 32px;
            }
            ::v-deep.el-input {
              width: 184px;
              height: 34px;
              .el-input__inner {
                height: 34px;
                line-height: 34px;
              }
            }
          }
          &:last-child {
            button {
              width: 150px;
              height: 36px;
              background: #3883ff;
              font-size: 18px;
              color: #fff;
              border: none;
              outline: none;
              cursor: pointer;
            }
            a {
              width: 150px;
              height: 36px;
              display: inline-block;
              border: 1px solid #004375;
              background: #004375;
              font-size: 18px;
              color: #fff;
              line-height: 34px;
              text-align: center;
              text-decoration: none;
              margin: 0 0 0 32px;
            }
          }
        }
      }
      ::v-deep.el-table {
        margin: 24px 0 0;
        border: none;
        &::before {
          display: none;
        }
        &::after {
          display: none;
        }
        tr {
          th {
            height: 48px;
            text-align: center;
            font-size: 18px;
            color: #333;
            border: none;
            border-left: 1px solid #ededed;
            border-bottom: 1px solid #ededed;
            border-top: 1px solid #ededed;
            &:nth-last-child(2) {
              border-right: 1px solid #ededed;
            }
          }
          td {
            height: 70px;
            font-size: 16px;
            color: #333;
            border: none;
            text-align: center;
            border-bottom: 1px solid #ededed;
            .cell {
              font-size: 16px;
              i {
                font-style: normal;
                margin: 0 4px;
                color: #3883ff;
                cursor: pointer;
                &.not {
                  color: #999;
                  cursor: not-allowed;
                }
              }
            }
          }
        }
        .table-merge {
          display: flex;
          align-items: center;
          .el-image {
            width: 54px;
            flex-shrink: 0;
            height: 54px;
          }
          label {
            margin: 0 0 0 18px;
          }
          &--img {
            width: 54px;
            flex-shrink: 0;
            height: 54px;
            display: inline-block;
          }
          &--title {
            color: #000;
            text-decoration: none;
            margin: 0 0 0 18px;
            &:hover {
              color: #3883ff;
            }
          }
        }
      }
      ::v-deep.page {
        display: flex;
        justify-content: flex-end;
        height: 36px;
        align-items: center;
        margin: 34px 0 0;
        & > a {
          width: 150px;
          height: 36px;
          border: 1px solid #dadada;
          font-size: 18px;
          color: #333;
          text-align: center;
          line-height: 34px;
          margin: 0 32px 0 0;
          text-decoration: none;
        }
        .el-pagination {
          & > button {
            height: 30px;
            line-height: 30px;
          }
          & > ul {
            li {
              height: 30px;
              line-height: 30px;
            }
          }
        }
      }
    }
  }
}
</style>
